<template>
  <div>
    <div class="divider"></div>
    <div class="title">create your IE account</div>
    <form class="form"  @submit.prevent="onSubmit"  action="">
      <div class="gold-title">Account information</div>
      <BaseSteps
      :step='test'></BaseSteps>
      <transition name="component-fade" mode="out-in">
        <BaseSignUpSt
        :processIndex.sync='test'
        v-if="test === 0"></BaseSignUpSt>
        <BaseSignUpSec
        :processIndex.sync='test'
        v-if="test === 2"></BaseSignUpSec>
        <BaseSignUpRd
        v-if="test >= 4"
        :processIndex.sync='test'
        @handleSignUp = 'handleSignUp'
        ></BaseSignUpRd>
      </transition>
    </form>
  </div>
</template>

<script>
import BaseSignUpSt from '@/components/BaseSignUpSt.vue'
import BaseSignUpSec from '@/components/BaseSignUpSec.vue'
import BaseSignUpRd from '@/components/BaseSignUpRd.vue'
import BaseSteps from '@/components/BaseSteps.vue'

export default {
  data () {
    return {
      step: 0,
      processIndex: 0,
      test: 0
    }
  },
  components: {
    BaseSignUpSt,
    BaseSignUpSec,
    BaseSignUpRd,
    BaseSteps
  },
  methods: {
    onSubmit () {
      return false
    },
    handleSignUp (e) {
      console.log(e)
    }
  },
  computed: {
  },
  watch: {
    test (newValue, oldValue) {
      console.log(newValue)
    }
  }
}
</script>

<style scoped>
.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active for below version 2.1.8 */ {
  opacity: 0;
}
.clearFix{
  margin-top: 20px;
}
.clearFix::after{
  clear: both;
  content: '';
  display: block;
}
.nextBtn{
  font-size: 20px;
  font-weight: 600;
  float: right;
  color: white;
  padding: 5px 20px;
  border-radius: 8px;
  background: #67c23a;
}
.nextBtn:disabled{
  background: #909399;
}
.nextBtn:hover{
  transform: scale(1.05);
}
.divider {
  width: 100px;
  height: 6px;
  background: #bc9a41;
  border-radius: 4px;
  margin: 160px auto 0;
}
input[type="checkbox"] {
  margin: 0;
}
.title {
  font-size: 36px;
  font-weight: 600;
  color: #163257;
  text-transform: uppercase;
  text-align: center;
  margin-top: 12px;
}
.form {
  width: 600px;
  margin: 60px auto 120px;
  padding: 40px 60px 20px 60px;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  transition: all 2s ease-in-out;
}
.gold-title {
  font-size: 24px;
  font-weight: 600;
  color: #bc9a41;
  text-transform: uppercase;
  text-align: center;
}

@media only screen and (max-width: 600px) {
.clearFix{
  margin-top: 20px;
}
.clearFix::after{
  clear: both;
  content: '';
  display: block;
}
.nextBtn{
  font-size: 20px;
  font-weight: 600;
  float: right;
  color: white;
  padding: 5px 20px;
  border-radius: 8px;
  background: #67c23a;
}
.nextBtn:hover{
  transform: scale(1.05);
}
.divider {
  width: 100px;
  height: 6px;
  background: #bc9a41;
  border-radius: 4px;
  margin: 12vh auto 0;
}
input[type="checkbox"] {
  margin: 0;
}
.title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #163257;
  text-transform: uppercase;
  text-align: center;
  margin-top: 12px;
}
.form {
  width: 90vw;
  margin: 3vh auto 0;
  padding: 5vw;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.gold-title {
  font-size: 1rem;
  font-weight: 600;
  color: #bc9a41;
  text-transform: uppercase;
  text-align: center;
}
}
</style>
