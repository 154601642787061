<template>
<div>
    <ul class="steps-container">
      <li class="item">
        <div class="item-top">
          <div :class="[step >= 1?'tickBg':'', 'round' ,'doneBorderGold']"></div>
          <div :class="[step >=2 ? 'pgGold':'','progressLine']"></div>
        </div>
        <div class="textColorBlue">Step1</div>
      </li>
      <li class="item">
       <div class="item-top">
          <div :class="[step >= 3?'doneBorderGold':'', step >= 4?'tickBg':'','round']"></div>
          <div :class="[step >=5 ? 'pgGold':'','progressLine']"></div>
        </div>
        <div class="textColorBlue">Step2</div>
      </li>
      <li class="done">
          <div class="item-top">
          <div :class="[step >=6?'doneBorderGold':'' ,step >=7?'tickBg':'' ,'round']"></div>
        </div>
        <div class="textColorBlue">Done</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    step: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style scoped>
.steps-container{
  max-width: 500px;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}
.round{
  width: 28px;
  height: 28px;
  border: 2px solid #C2C2C2;
  border-radius: 1000px;
  margin: 0 auto;
  flex-shrink: 0;
}
.item{
  width: 100%;
}
.item-top{
  display: flex;
  align-items: center;
}
.progressLine{
  width: 100%;
  flex-grow: 1;
  height: 2px;
  border:1px solid #d0d0d0;
}
.done{
  max-width: 25px;
}
.pgGold{
  border-color:  #BC9A41;
}
.doneBorderGold{
  border-color:  #BC9A41;
  box-shadow: inset 0 0 2px #BC9A41;
}
.tickBg{
  background: url('../assets/icon/tick.png') no-repeat center center / 80%;
}
.textColorBlue{
  color: #BC9A41;
  font-size: 14px;
}

</style>
