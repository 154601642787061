<template>
  <div class="inner-form">
  <BaseSelection/>
          <input
            class="form-input"
            placeholder="Choose Language"
            type="text"
            id='languages'
            v-model="languageValue"
            list="languages"
          />
          <datalist id="languages">
            <option value="Chinese"></option>
            <option value="English"></option>
          </datalist>
          <input
            class="form-input"
            placeholder="Choose Currency"
            type="text"
            v-model="currencyValue"
            list="Currencies"
          />
          <datalist id="Currencies">
            <option value="NZD"></option>
            <option value="AUD"></option>
            <option value="CNY"></option>
            <option value="CAD"></option>
            <option value="JPY"></option>
            <option value="SGD"></option>
          </datalist>
    <div class="clearFix">
        <button class="nextBtn" :disabled ='checkAllValid'
        @click="handleNextComponent"> NEXT </button>
      </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import BaseSelection from '@/components/BaseSelection'
export default {
  components: {
    BaseSelection
  },
  data () {
    return {
      countryValue: '',
      languageValue: '',
      currencyValue: ''
    }
  },
  methods: {
    handleNextComponent () {
      this.$emit('update:processIndex', 4)
      this.setStateAction({ content: 'countryValue', value: this.countryValue })
      this.setStateAction({ content: 'languageValue', value: this.languageValue })
      this.setStateAction({ content: 'currencyValue', value: this.currencyValue })
    },
    ...mapActions('signup', ['setStateAction']),
    test () {
      console.log(this.countryValue)
    }
  },
  computed: {
    checkAllValid () {
      if (this.countryValue && this.languageValue && this.currencyValue) {
        return false
      } else {
        return true
      }
    }
  }
}
</script>

<style scoped>

.form-input {
  margin-top: 20px;
  height: 48px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  padding-left: 16px;
  outline: none;
  width: 100%;
}
.inner-form {
  width: 100%;
  margin: 20px auto 0;
  background: #ffffff;
  box-sizing: border-box;
}
.clearFix{
  margin-top: 20px;
}
.nextBtn{
  font-size: 20px;
  font-weight: 600;
  color: white;
  width: 100%;
  height: 56px;
  padding: 5px 20px;
  border-radius: 2px;
  background: #163257;
  letter-spacing: 4px;
}
.nextBtn:disabled{
  background: rgba(22, 50, 87, 0.2);
}
</style>
