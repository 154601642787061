<template>
   <div
    class="form_selection width100"
    placeholder="Choose Country"
    list= 'Countries'
    required
    type="text">
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.form_selection{
  margin-top: 20px;
  height: 48px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  padding-left: 16px;
  outline: none;
  width: 100%;
  position: relative;
  cursor: pointer;
}
.form_selection:hover::after{
  opacity: 1;
}
.form_selection::after{
  position: absolute;
  content: '';
  width: 10px;
  height: 10px;
  display: block;
  border-bottom: 5px solid #555;
  border-right: 5px solid #555;
  border-top: 5px solid transparent;
  border-left: 5px solid transparent;
  transform: rotate(45deg);
  right: 3%;
  top: calc(50% - 8px);
  opacity: 0;
}
</style>
