<template>
  <div class="inner-form">
          <input
            class="form-input"
            placeholder="Region(optional)"
            v-model="regionValue"
          />
          <input
            class="form-input"
            placeholder="Town/City"
            v-model="cityValue"
            required
          />
          <input
            class="form-input"
            placeholder="Postal code"
            v-model="postalCode"
            required
          />
          <div class=" flex justify-between">
          <div class="phoneCode-container">
          <input class="form-input"
          list='phoneList'
          v-model="phoneCode"
          placeholder="+86" />
          </div>
          <div class="phoneNum-container">
          <input
            class="form-input"
            placeholder="Mobile phone"
            v-model="phoneNum"
          />
          </div>
          </div>
          <datalist id='phoneList'>
            <option value="+1"></option>
            <option value="+61"></option>
            <option value="+64"></option>
            <option value="+65"></option>
            <option value="+81"></option>
            <option value="+86"></option>
            <option value="+852"></option>
          </datalist>
          <div class="clearFix">
        <button class="nextBtn" :disabled ='checkAllValid'
        @click="handleNextComponent"> FINISH </button>
      </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import axios from 'axios'
export default {
  data () {
    return {
      regionValue: '',
      cityValue: '',
      postalCode: '',
      phoneCode: '',
      phoneNum: ''
    }
  },
  methods: {
    handleNextComponent () {
      this.$emit('update:processIndex', 7)
      console.log({
        email_address: this.email,
        password: this.pwd1,
        country_value: this.countryValue,
        language_value: this.languageValue,
        currency_value: this.currencyValue,
        region_value: this.regionValue,
        city_value: this.cityValue,
        postal_code: this.postalCode,
        phone_num: this.phoneNum
      })
      axios({
        method: 'post',
        url: 'https://fx.iemoney.co.nz/api/reg-user',
        data: {
          email_address: this.email,
          password: this.pwd1,
          country_value: this.countryValue,
          language_value: this.languageValue,
          currency_value: this.currencyValue,
          region_value: this.regionValue,
          city_value: this.cityValue,
          postal_code: this.postalCode,
          phone_num: this.phoneNum
        }
      }).then((res) => {
        console.log(res)
      })
    },
    ...mapActions('signup', ['ChangeFormAction'])
  },
  computed: {
    ...mapState('signup', ['email', 'pwd1', 'countryValue', 'languageValue', 'currencyValue']),
    checkAllValid () {
      if (this.cityValue && this.postalCode && this.phoneCode && this.phoneNum) {
        return false
      } else {
        return true
      }
    }
  }
}
</script>

<style scoped>
.form-input {
  margin-top: 20px;
  height: 48px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  padding-left: 16px;
  outline: none;
  width: 100%;
}
.inner-form {
  width: 100%;
  margin: 20px auto 0;
  background: #ffffff;
  box-sizing: border-box;
}
.clearFix{
  margin-top: 20px;
}
.nextBtn{
  font-size: 20px;
  font-weight: 600;
  color: white;
  width: 100%;
  height: 56px;
  padding: 5px 20px;
  border-radius: 2px;
  background: #163257;
  letter-spacing: 4px;
}
.nextBtn:disabled{
  background: rgba(22, 50, 87, 0.2);
}
.phoneNum-container{
  width: 317px;
}
.phoneCode-container{
  width: 153px;
}
@media only screen and (max-width:600px){
  .phoneNum-container{
  width: 66%;
}
.phoneCode-container{
  width: 32%;
}
}
</style>
