<template>
  <div class="inner-form">
    <BaseInput
      :regObj="[emailReg]"
      id_type="email"
      i_type="text"
      :isAutoFocus='true'
      :input_value="email"
      @handleUpdateValue="handleChildUpdate"
      placeHolder="Email Adress"
      :isProve.sync='emailPass'
    >
    </BaseInput>
    <BaseInput
      :regObj="[pwdReg]"
      id_type="pwd1"
      :i_type.sync="typePassword"
      @changeType="(val) => (typePassword = val)"
      :isPwd='true'
      placeHolder="Password"
      :input_value="pwd1"
      :customerProp="pwd2"
      :customerTriggle="true"
      :isProve.sync = 'pwd1Pass'
      @handleUpdateValue="(val) => (pwd1 = val)"
    >
    </BaseInput>
    <BaseInput
      :isPwd='true'
      :regObj="[pwdReg]"
      :i_type.sync="typePassword"
      @changeType="(val) => (typePassword = val)"
      id_type="pwd2"
      placeHolder="Confirm Password"
      :input_value="pwd2"
      :customerTriggle="true"
      :customerProp="pwd1"
      :isProve.sync = 'pwd2Pass'
      @handleUpdateValue="(val) => (pwd2 = val)"
    >
    </BaseInput>
    <div class="clearFix">
        <button class="nextBtn" :disabled ='checkAllValid'
        @click="handleNextComponent"> NEXT </button>
      </div>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput.vue'
import { emailReg, pwdReg } from '../utils/validation.js'
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      emailReg,
      pwdReg,
      email: '',
      emailPass: false,
      pwd1Pass: false,
      pwd2Pass: false,
      pwd1: '',
      pwd2: '',
      typePassword: 'password',
      isNextDisable: true
    }
  },
  props: {
    processIndex: {
      type: Number
    }
  },
  methods: {
    handleChildUpdate (val) {
      this.email = val
    },
    handleNextComponent () {
      this.$emit('update:processIndex', 2)
      this.setStateAction({ content: 'email', value: this.email })
      this.setStateAction({ content: 'pwd1', value: this.pwd1 })
    },
    ...mapActions('signup', ['setStateAction'])
  },
  components: {
    BaseInput
  },
  computed: {
    checkAllValid () {
      if (this.emailPass && this.pwd1Pass && this.pwd2Pass) {
        return false
      } else {
        return true
      }
    }
  },
  watch: {
    // pwd1Pass (newV) {
    //   console.log('pw1:' + newV)
    // },
    // pwd2Pass (newV) {
    //   console.log('pw2:' + newV)
    // }
  }
}
</script>

<style scoped>
.inner-form {
  width: 100%;
  margin: 20px auto 0;
  background: #ffffff;
  box-sizing: border-box;
  height: auto;
  transition: all 2s ease-in-out;
}
.clearFix{
  margin-top: 20px;
}

.nextBtn{
  font-size: 20px;
  font-weight: 600;
  color: white;
  width: 100%;
  height: 56px;
  padding: 5px 20px;
  border-radius: 2px;
  background: #163257;
  letter-spacing: 4px;
}
.nextBtn:disabled{
  background: rgba(22, 50, 87, 0.2);
}

</style>
